import { SiteIdOverrideConfig } from "stores/wizard/config";
import { timeValues24HoursFormat } from "src/components/shared/TimeDropdown/config/time/timeValues";

export const carsConfigOverrideID: SiteIdOverrideConfig = {
  car: {
    location: {
      destination: {
        inputName: "returnName",
      },
      origin: {
        inputName: "pickupName",
      },
    },
    date: {
      queryParam: {
        start: "",
        end: "",
      },
    },
    form: {
      action: "https://cars.cartrawler.com/expedia/{locale}-id/book#/vehicles",
    },
    timeValues: timeValues24HoursFormat,
    defaultShowingTime: "10:30",
    isCarTrawler: true,
    showVendorOptions: false,
    clientID: 312156,
    residenceID: "ID",
  },
};
