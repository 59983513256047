import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId1146: SiteIdOverrideConfig = {
  package: {
    heading: {
      lobNotSupported: "wizard.package.invalid.header.lobNotSupported.text",
    },
  },
  car: {
    iHaveDiscount: "wizard.car.having.brand.title",
    preferredVendors: [
      {
        experimentCode: "Cars_preferred_vendor_filter",
        bucket: 1,
        vendors: {
          NO_PREFERENCE: {
            includeInDropdown: false,
          },
          AC: {
            includeInDropdown: false,
          },
          AD: {
            includeInDropdown: false,
          },
          AL: {
            includeInDropdown: false,
          },
          ZI: {
            includeInDropdown: true,
          },
          ZD: {
            includeInDropdown: true,
          },
          ZR: {
            includeInDropdown: false,
          },
          EY: {
            includeInDropdown: false,
          },
          ET: {
            includeInDropdown: false,
          },
          EP: {
            includeInDropdown: false,
          },
          FX: {
            includeInDropdown: false,
          },
          ZE: {
            includeInDropdown: false,
          },
          MW: {
            includeInDropdown: false,
          },
          ZL: {
            includeInDropdown: false,
          },
          NU: {
            includeInDropdown: false,
          },
          ZA: {
            includeInDropdown: true,
          },
          RO: {
            includeInDropdown: false,
          },
          SX: {
            includeInDropdown: false,
          },
          ZT: {
            includeInDropdown: false,
          },
          SV: {
            includeInDropdown: false,
          },
          SC: {
            includeInDropdown: false,
          },
          GI: {
            includeInDropdown: false,
          },
          NC: {
            includeInDropdown: false,
          },
          RW: {
            includeInDropdown: false,
          },
          PR: {
            includeInDropdown: false,
          },
        },
      },
    ],
  },
};
