import { FlightConfig } from "stores/wizard/config";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";
import { FlightLegConfig } from "stores/wizard/config/staticConfigs/flight/typings";

const travelersConfig = {
  icon: "person",
  labelToken: "wizard.roomPicker.travelers.title",
  dialogTitleToken: "wizard.roomPicker.travelers.title",
  dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
  childrenCaptionToken: "wizard.roomPicker.children.withInfants.caption",
  infantsCaptionToken: "wizard.roomPicker.infants.caption",
  youngestChildAgeTextToken: "",
  youngestInfantAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
  invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
  invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
  invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
  invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
  invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
  invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.warning.unattendedInfantOnLap.text",
  invalidNumberOfInfantPerTravelerMessageToken: "wizard.travelers.warning.invalidNumberOfInfantPerTraveler.text",
  travelersToken: "wizard.roomPicker.travelers",
  roomsGuestsToken: "wizard.roomPicker.roomsGuests",
  minBookableTravelers: 1,
  defaultTravelers: 1,
  maxBookableTravelers: 6,
  minAdultAge: 12,
  minChildAge: 2,
  maxChildAge: 17,
  minInfantAge: 0,
  maxInfantAge: 1,
  minInfantPerTraveler: 2,
  minAdultsPerRoom: 1,
  maxAdultsPerRoom: 6,
  minChildrenPerRoom: 0,
  maxChildrenPerRoom: 6,
  minInfantsPerRoom: 0,
  maxInfantsPerRoom: 6,
  maxRooms: 1,
  validateChildInfantValues: true,
  withRooms: false,
  withAges: true,
  withFlight: true,
  withInfants: true,
  hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.FLIGHT_SEARCH,
  noRehydratationValues: {
    numOfTravelers: 1,
    name: "adults",
  },
};

const MAX_NUMBER_OF_ADDITIONAL_LEGS = 4;

const createLegConfigs = () => {
  const legArray = new Array<FlightLegConfig>(MAX_NUMBER_OF_ADDITIONAL_LEGS);

  //start additional leg count at 2; legs are 1-indexed with the default origin/dest input as leg 1
  for (let i = 0; i < MAX_NUMBER_OF_ADDITIONAL_LEGS; i++) {
    legArray[i] = flightLegConfig(i + 2);
  }

  return legArray;
};

const getMaxNumberOfAdditionalLegs = () => {
  return MAX_NUMBER_OF_ADDITIONAL_LEGS;
};

export const flightLegConfig = (legNumber: number) => {
  return {
    date: {
      ISOSubmissionSupport: false,
      format: "yyyy-MM-dd",
      submissionFormat: "yyyy-MM-dd",
      daysBookableInAdvance: 331,
      midnightBookingOffset: 4,
      maxDaysRange: 0,
      invalidStartDateMessageToken: "wizard.datePicker.warning.invalidDepartingDate.text",
      invalidEndDateMessageToken: "",
      invalidMaxDatesRangeMessageToken: "",
      invalidStartDateOutOfRangeMessageToken: "",
      invalidEndDateOutOfRangeMessageToken: "",
      start: {
        labelToken: "wizard.departing.label",
      },
      end: {
        labelToken: "wizard.returning.label",
      },
      queryParam: {
        start: "",
        end: "",
      },
      dialogId: `flights-departure-${legNumber}`,
    },
    location: {
      destination: {
        inputName: `leg${legNumber}-destination`,
        icon: "place",
        invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
        invalidOriginSameAsDestinationKey: "wizard.destination.warning.originDifferentFromDestination.text",
        labelToken: "wizard.flight.destination.labelv2",
        typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.destination",
        typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
        pwaDialogQueryParam: `destination-flight-${legNumber}`,
        essAdapterConfig: {
          lob: "FLIGHTS",
          client: "Homepage",
          features: "nearby_airport|ta_hierarchy|consistent_display",
          maxResults: 10,
          regionType: 4959,
          dest: true,
        },
      },
      origin: {
        inputName: `leg${legNumber}-origin`,
        icon: "place",
        invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
        labelToken: "wizard.flight.origin.label",
        typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.origin",
        typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
        pwaDialogQueryParam: `origin-flight-${legNumber}`,
        essAdapterConfig: {
          lob: "FLIGHTS",
          client: "Homepage",
          features: "nearby_airport|ta_hierarchy|consistent_display",
          maxResults: 10,
          regionType: 4959,
          dest: false,
        },
      },
    },
  };
};

export const flightConfig: FlightConfig = {
  navigation: {
    tabIcon: "lob_flights",
    tabTitleToken: "wizard.flight.tab.title.label",
  },
  location: {
    destination: {
      inputName: "leg1-destination",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "wizard.destination.warning.originDifferentFromDestination.text",
      labelToken: "wizard.flight.destination.labelv2",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.destination",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "destination-flight",
      essAdapterConfig: {
        lob: "FLIGHTS",
        client: "Homepage",
        features: "nearby_airport|ta_hierarchy|consistent_display",
        maxResults: 10,
        regionType: 4959,
        dest: true,
      },
    },
    origin: {
      inputName: "leg1-origin",
      icon: "place",
      invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
      labelToken: "wizard.flight.origin.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.origin",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "origin-flight",
      essAdapterConfig: {
        lob: "FLIGHTS",
        client: "Homepage",
        features: "nearby_airport|ta_hierarchy|consistent_display",
        maxResults: 10,
        regionType: 4959,
        dest: false,
      },
    },
  },
  date: {
    ISOSubmissionSupport: false,
    format: "yyyy-MM-dd",
    submissionFormat: "dd/MM/yyyy",
    daysBookableInAdvance: 331,
    midnightBookingOffset: 4,
    maxDaysRange: 331,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidDepartingDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidReturningDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    start: {
      labelToken: "wizard.departing.label",
    },
    end: {
      labelToken: "wizard.returning.label",
    },
    queryParam: {
      start: "fromDate",
      end: "toDate",
    },
    defaultOffsetDates: {
      start: 14,
      end: 15,
    },
    dialogId: "flights-departure-arrival",
  },
  hotelDate: {
    ISOSubmissionSupport: false,
    format: "yyyy-MM-dd",
    submissionFormat: "dd/MM/yyyy",
    daysBookableInAdvance: 500,
    midnightBookingOffset: 4,
    maxDaysRange: 28,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidFromDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidToDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "wizard.datePicker.warning.checkInDateOutOfRange.text",
    invalidEndDateOutOfRangeMessageToken: "wizard.datePicker.warning.checkOutDateOutOfRange.text",
    invalidOnewayHotelStartDateMessageToken: "wizard.datePicker.warning.invalidOneWayCheckInDate.text",
    start: {
      labelToken: "datePicker.fromDate.label",
    },
    end: {
      labelToken: "datePicker.toDate.label",
    },
    queryParam: {
      start: "checkInDate",
      end: "checkOutDate",
    },
    dialogId: "check-in-out",
  },
  travelers: travelersConfig,
  travelersWithHotel: {
    ...travelersConfig,
    maxRooms: 6,
    withRooms: true,
    withFlight: true,
    withInfants: true,
    hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.PACKAGE_SEARCH,
  },
  travelersWithCar: {
    ...travelersConfig,
    hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.PACKAGE_SEARCH,
  },
  form: {
    action: "/Flights-Search",
    submitButton: {
      locToken: "wizard.submit.label",
      locWarningsToken: "wizard.submit.warnings",
      locWarningLinkToken: "wizard.submit.warning.link",
      locWarningsWithNumberToken: "wizard.submit.warningsWithNumber",
      locFirstWarningLinkToken: "wizard.submit.warnings.link",
    },
  },
  multiCityFlight: {
    maxNumberOfAdditionalLegs: getMaxNumberOfAdditionalLegs(),
    legs: createLegConfigs(),
    minNumberOfLegs: 2,
    referrerId: "SearchClick_Flights_MultiDest",
  },
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "off",
    locTitleToken: "wizard.mobile.label.flight",
    differentiatedTitles: false,
    locSubtitleTextToken: "",
    locTitleDesktopTextToken: "",
  },
  elementId: "wizard-flight-pwa",
  enableAddCar: false,
  enableAddHotel: false,
  enableFlightFareCalendar: false,
  autoOpenFlightFareCalendar: false,
  enableNonStopFlight: false,
  referrerId: "SearchClick_Flights",
  airlineOptions: "",
  cabinClass: {},
  halfWizard: false,
  siteIdsWithoutSoftPackages: ["76", "77"],
};
